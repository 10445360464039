<template>
  <div id="app">
    <!-- ルーティングによる表示 -->
    <router-view/>
    <p class="copyright"><small>&copy;&nbsp;2024&nbsp;PICOMINI</small></p>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style>
/* デフォルトCSS */
@import "./default.css";

/* ゲーム機ごとのカラー設定・アイコン画像 */
/* ファミコン */
:root .fc {
  --mainColor: #d3381c;
  --btnColor: #696969;
  --fmBtnColor: #c8c2be;
  --consoleImage: url("./assets/images/ic_fc.png");
}
:root .fc_soft {--softImage: url("./assets/images/ic_fc_soft.png");}
:root .fc_soft0 {--softImage: url("./assets/images/ic_fc_soft0.png");}
:root .fc_soft1 {--softImage: url("./assets/images/ic_fc_soft1.png");}
:root .fc_soft2 {--softImage: url("./assets/images/ic_fc_soft2.png");}
:root .fc_soft3 {--softImage: url("./assets/images/ic_fc_soft3.png");}
:root .fc_soft4 {--softImage: url("./assets/images/ic_fc_soft4.png");}
:root .fc_soft5 {--softImage: url("./assets/images/ic_fc_soft5.png");}
:root .fc_soft10 {--softImage: url("./assets/images/ic_fc_soft10.png");}
:root .fc_soft11 {--softImage: url("./assets/images/ic_fc_soft11.png");}
:root .fc_soft12 {--softImage: url("./assets/images/ic_fc_soft12.png");}
:root .fc_soft13 {--softImage: url("./assets/images/ic_fc_soft13.png");}
:root .fc_soft20 {--softImage: url("./assets/images/ic_fc_soft20.png");}
:root .fc_soft21 {--softImage: url("./assets/images/ic_fc_soft21.png");}
:root .fc_soft22 {--softImage: url("./assets/images/ic_fc_soft22.png");}
:root .fc_soft30 {--softImage: url("./assets/images/ic_fc_soft30.png");}
:root .fc_soft31 {--softImage: url("./assets/images/ic_fc_soft31.png");}
:root .fc_soft32 {--softImage: url("./assets/images/ic_fc_soft32.png");}
:root .fc_soft33 {--softImage: url("./assets/images/ic_fc_soft33.png");}
:root .fc_soft34 {--softImage: url("./assets/images/ic_fc_soft34.png");}
:root .fc_soft40 {--softImage: url("./assets/images/ic_fc_soft40.png");}
:root .fc_soft41 {--softImage: url("./assets/images/ic_fc_soft41.png");}
:root .fc_soft42 {--softImage: url("./assets/images/ic_fc_soft42.png");}
:root .fc_soft43 {--softImage: url("./assets/images/ic_fc_soft43.png");}
:root .fc_soft44 {--softImage: url("./assets/images/ic_fc_soft44.png");}
:root .fc_soft50 {--softImage: url("./assets/images/ic_fc_soft50.png");}
:root .fc_soft51 {--softImage: url("./assets/images/ic_fc_soft51.png");}
:root .fc_soft52 {--softImage: url("./assets/images/ic_fc_soft52.png");}
:root .fc_soft53 {--softImage: url("./assets/images/ic_fc_soft53.png");}
:root .fc_soft54 {--softImage: url("./assets/images/ic_fc_soft54.png");}
:root .fc_soft55 {--softImage: url("./assets/images/ic_fc_soft55.png");}
:root .fc_soft56 {--softImage: url("./assets/images/ic_fc_soft56.png");}
:root .fc_soft60 {--softImage: url("./assets/images/ic_fc_soft60.png");}
:root .fc_soft61 {--softImage: url("./assets/images/ic_fc_soft61.png");}
:root .fc_soft62 {--softImage: url("./assets/images/ic_fc_soft62.png");}
:root .fc_soft63 {--softImage: url("./assets/images/ic_fc_soft63.png");}
:root .fc_soft64 {--softImage: url("./assets/images/ic_fc_soft64.png");}
:root .fc_soft65 {--softImage: url("./assets/images/ic_fc_soft65.png");}
:root .fc_soft80 {--softImage: url("./assets/images/ic_fc_soft80.png");}
:root .fc_soft81 {--softImage: url("./assets/images/ic_fc_soft81.png");}
:root .fc_soft82 {--softImage: url("./assets/images/ic_fc_soft82.png");}
:root .fc_soft90 {--softImage: url("./assets/images/ic_fc_soft90.png");}
:root .fc_soft91 {--softImage: url("./assets/images/ic_fc_soft91.png");}
:root .fc_soft92 {--softImage: url("./assets/images/ic_fc_soft92.png");}
:root .fc_soft93 {--softImage: url("./assets/images/ic_fc_soft93.png");}
:root .fc_soft94 {--softImage: url("./assets/images/ic_fc_soft94.png");}
:root .fc_soft95 {--softImage: url("./assets/images/ic_fc_soft95.png");}
:root .fc_soft96 {--softImage: url("./assets/images/ic_fc_soft96.png");}
:root .fc_soft97 {--softImage: url("./assets/images/ic_fc_soft97.png");}
:root .fc_soft98 {--softImage: url("./assets/images/ic_fc_soft98.png");}
:root .fc_soft99 {--softImage: url("./assets/images/ic_fc_soft99.png");}
:root .fc_soft100 {--softImage: url("./assets/images/ic_fc_soft100.png");}

/* ディスクシステム */
:root .fds {
  --mainColor: #d3381c;
  --btnColor: #696969;
  --fmBtnColor: #c8c2be;
  --consoleImage: url("./assets/images/ic_fds.png");
}
:root .fds_soft {--softImage: url("./assets/images/ic_fds_soft.png");}
:root .fds_soft1 {--softImage: url("./assets/images/ic_fds_soft1.png");}
:root .fds_soft2 {--softImage: url("./assets/images/ic_fds_soft2.png");}

/* ゲームボーイ */
:root .gb {
  --mainColor: #aaaadd;
  --btnColor: #df5499;
  --fmBtnColor: #c8c2be;
  --consoleImage: url("./assets/images/ic_gb.png");
}
:root .gb_soft {--softImage: url("./assets/images/ic_gb_soft.png");}
:root .gb_soft0 {--softImage: url("./assets/images/ic_gb_soft0.png");}
:root .gb_soft1 {--softImage: url("./assets/images/ic_gb_soft1.png");}
:root .gb_soft2 {--softImage: url("./assets/images/ic_gb_soft2.png");}
:root .gb_soft3 {--softImage: url("./assets/images/ic_gb_soft3.png");}
:root .gb_soft4 {--softImage: url("./assets/images/ic_gb_soft4.png");}
:root .gb_soft5 {--softImage: url("./assets/images/ic_gb_soft5.png");}
:root .gb_soft6 {--softImage: url("./assets/images/ic_gb_soft6.png");}
:root .gb_soft7 {--softImage: url("./assets/images/ic_gb_soft7.png");}
:root .gb_soft8 {--softImage: url("./assets/images/ic_gb_soft8.png");}
:root .gb_soft9 {--softImage: url("./assets/images/ic_gb_soft9.png");}
:root .gb_soft10 {--softImage: url("./assets/images/ic_gb_soft10.png");}
:root .gb_soft11 {--softImage: url("./assets/images/ic_gb_soft11.png");}
:root .gb_soft12 {--softImage: url("./assets/images/ic_gb_soft12.png");}
:root .gb_soft13 {--softImage: url("./assets/images/ic_gb_soft13.png");}
:root .gb_soft14 {--softImage: url("./assets/images/ic_gb_soft14.png");}
:root .gb_soft15 {--softImage: url("./assets/images/ic_gb_soft15.png");}
:root .gb_soft16 {--softImage: url("./assets/images/ic_gb_soft16.png");}
:root .gb_soft17 {--softImage: url("./assets/images/ic_gb_soft17.png");}
:root .gb_soft18 {--softImage: url("./assets/images/ic_gb_soft18.png");}

/* スーパーファミコン */
:root .sfc {
  --mainColor: #a9a9a9;
  --btnColor: #7d7d7d;
  --btnColor1: #339900;
  --btnColor2: #3366ff;
  --btnColor3: #fff333;
  --btnColor4: #cc3333;
  --fmBtnColor: #c8c2be;
  --consoleImage: url("./assets/images/ic_sfc.png");
}
:root .sfc_soft {--softImage: url("./assets/images/ic_sfc_soft.png");}
:root .sfc_soft0 {--softImage: url("./assets/images/ic_sfc_soft0.png");}
:root .sfc_soft1 {--softImage: url("./assets/images/ic_sfc_soft1.png");}
:root .sfc_soft2 {--softImage: url("./assets/images/ic_sfc_soft2.png");}
:root .sfc_soft3 {--softImage: url("./assets/images/ic_sfc_soft3.png");}
:root .sfc_soft4 {--softImage: url("./assets/images/ic_sfc_soft4.png");}
:root .sfc_soft5 {--softImage: url("./assets/images/ic_sfc_soft5.png");}
:root .sfc_soft10 {--softImage: url("./assets/images/ic_sfc_soft10.png");}
:root .sfc_soft11 {--softImage: url("./assets/images/ic_sfc_soft11.png");}
:root .sfc_soft12 {--softImage: url("./assets/images/ic_sfc_soft12.png");}
:root .sfc_soft13 {--softImage: url("./assets/images/ic_sfc_soft13.png");}
:root .sfc_soft20 {--softImage: url("./assets/images/ic_sfc_soft20.png");}
:root .sfc_soft21 {--softImage: url("./assets/images/ic_sfc_soft21.png");}
:root .sfc_soft22 {--softImage: url("./assets/images/ic_sfc_soft22.png");}
:root .sfc_soft30 {--softImage: url("./assets/images/ic_sfc_soft30.png");}
:root .sfc_soft31 {--softImage: url("./assets/images/ic_sfc_soft31.png");}
:root .sfc_soft32 {--softImage: url("./assets/images/ic_sfc_soft32.png");}
:root .sfc_soft33 {--softImage: url("./assets/images/ic_sfc_soft33.png");}
:root .sfc_soft34 {--softImage: url("./assets/images/ic_sfc_soft34.png");}
:root .sfc_soft40 {--softImage: url("./assets/images/ic_sfc_soft40.png");}
:root .sfc_soft41 {--softImage: url("./assets/images/ic_sfc_soft41.png");}
:root .sfc_soft42 {--softImage: url("./assets/images/ic_sfc_soft42.png");}
:root .sfc_soft43 {--softImage: url("./assets/images/ic_sfc_soft43.png");}
:root .sfc_soft44 {--softImage: url("./assets/images/ic_sfc_soft44.png");}
:root .sfc_soft50 {--softImage: url("./assets/images/ic_sfc_soft50.png");}
:root .sfc_soft51 {--softImage: url("./assets/images/ic_sfc_soft51.png");}
:root .sfc_soft52 {--softImage: url("./assets/images/ic_sfc_soft52.png");}
:root .sfc_soft53 {--softImage: url("./assets/images/ic_sfc_soft53.png");}
:root .sfc_soft54 {--softImage: url("./assets/images/ic_sfc_soft54.png");}
:root .sfc_soft55 {--softImage: url("./assets/images/ic_sfc_soft55.png");}
:root .sfc_soft56 {--softImage: url("./assets/images/ic_sfc_soft56.png");}
:root .sfc_soft60 {--softImage: url("./assets/images/ic_sfc_soft60.png");}
:root .sfc_soft61 {--softImage: url("./assets/images/ic_sfc_soft61.png");}
:root .sfc_soft62 {--softImage: url("./assets/images/ic_sfc_soft62.png");}
:root .sfc_soft63 {--softImage: url("./assets/images/ic_sfc_soft63.png");}
:root .sfc_soft64 {--softImage: url("./assets/images/ic_sfc_soft64.png");}
:root .sfc_soft65 {--softImage: url("./assets/images/ic_sfc_soft65.png");}
:root .sfc_soft98 {--softImage: url("./assets/images/ic_sfc_soft98.png");}
:root .sfc_soft99 {--softImage: url("./assets/images/ic_sfc_soft99.png");}
:root .sfc_soft100 {--softImage: url("./assets/images/ic_sfc_soft100.png");}

/* PCエンジン */
:root .pce {
  --mainColor: #888;
  --subColor: #fa5446;
  --btnColor: #585b54;
  --fmBtnColor: #c8c2be;
  --consoleImage: url("./assets/images/ic_pce.png");
}
:root .pce_soft {--softImage: url("./assets/images/ic_gb_soft.png");}
:root .pce_soft1 {--softImage: url("./assets/images/ic_pce_soft1.png");}
:root .pce_soft2 {--softImage: url("./assets/images/ic_pce_soft2.png");}
:root .pce_soft3 {--softImage: url("./assets/images/ic_pce_soft3.png");}
:root .pce_soft4 {--softImage: url("./assets/images/ic_pce_soft4.png");}
:root .pce_soft5 {--softImage: url("./assets/images/ic_pce_soft5.png");}

/* メガドライブ */
:root .md {
  --mainColor: #333;
  --subColor: #666;
  --btnColor: #0095d9;
  --fmBtnColor: #d3d3d3;
  --consoleImage: url("./assets/images/ic_md.png");
}
:root .md_soft {--softImage: url("./assets/images/ic_md_soft.png");}
:root .md_soft1 {--softImage: url("./assets/images/ic_md_soft1.png");}
:root .md_soft2 {--softImage: url("./assets/images/ic_md_soft2.png");}
:root .md_soft3 {--softImage: url("./assets/images/ic_md_soft3.png");}
:root .md_soft4 {--softImage: url("./assets/images/ic_md_soft4.png");}
:root .md_soft5 {--softImage: url("./assets/images/ic_md_soft5.png");}
:root .md_soft6 {--softImage: url("./assets/images/ic_md_soft6.png");}

/* ネオジオ */
:root .ng {
  --mainColor: #333;
  --subColor: #666;
  --btnColor: #ebce87;
  --fmBtnColor: #d3d3d3;
  --consoleImage: url("./assets/images/ic_ng.png");
}
:root .ng_soft {--softImage: url("./assets/images/ic_ng_soft.png");}
:root .ng_soft1 {--softImage: url("./assets/images/ic_ng_soft1.png");}
:root .ng_soft2 {--softImage: url("./assets/images/ic_ng_soft2.png");}

/* ヘッダー */
header {
  position: fixed;
  left: 0;
  width: 100vw;
  height: 53px;
	background-color: var(--mainColor);
	border-bottom: 1px #ddd solid;
	padding: 0;
	margin: 0;
	z-index: 10;
}
.header-inner {
	display: flex;
	flex-flow: row nowrap;
	position: relative;
  max-width: 1024px;
	padding: 10px 10px 0;
  margin: 0 auto;
}
/* ヘッダータイトル */
.header-inner h1 {
  color: #fff;
  margin: 0;
}
/* [<]ボタン */
.btnBack {
    position: relative;
    display: block;
    width: 32px;
    height: 39px;
}
.btnBack::before {
  position: absolute;
  top: 13px;
  left: 10px;
  width: 12px;
  height: 12px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  content: "";
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
/* コンテンツ */
.container {
  width: 100vw;
  max-width: 1024px;
  min-height: calc(100vh - 35px);
  height: 100%;
	padding: 80px 10px 0;
  margin: 0 auto;
}
/* 標準ボタン */
.button {
  display: block;
  width: 100px;
  height: 40px;
  padding: 10px;
  margin: 10px auto 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
  line-height: 1.2;
  cursor: pointer;
}
/* コピーライト */
.copyright {
  height: 35px;
  text-align: center;
}
</style>