<template>
  <div class="menu">
    <header>
      <div class="header-inner">
        <!-- [<]戻るボタン -->
        <!--<router-link :to="{name: 'home', params: {outerSlide: 'slideL'}}">-->
          <span class="btnBack" @click="onBtnBack"></span>
        <!--</router-link>-->
        <h1>オプションメニュー</h1>
      </div>
    </header>    
    <transition name="slideR" appear>
    <div class="container">
      <!-- バージョン表示  -->
      <section class="menuList">
      <!--<li class="dmyLi"></li>-->
      <h2 class="version">バージョン<span>1.5.1</span></h2>
      </section>
      <!-- バックアップデータの保存 -->
      <section class="menuList menuListBtn">
        <h2>バックアップデータの保存</h2>
        <p>登録済の全てのコレクションデータをローカルのファイルへ出力します。<span class="menuButton btnDownload" @click="fileDownload"></span></p>
      </section>
      <!-- バックアップデータの復元 -->
      <section class="menuList menuListBtn">
        <h2>バックアップデータの復元</h2>
        <p>バックアップファイルを読み込み、コレクションデータを復元します。
        <label class="menuButton btnUpload" for="upFile"></label>
        <input type="file" ref="upfile" id="upFile" v-on:change="fileImport" onclick="this.value=null;" accept="text/plain, application/json">
        </p>
      </section>
      <!-- 利用規約  -->
      <section class="menuList">
      <h2>利用規約</h2>
        <!-- 利用規約情報を表示 -->
        <Kiyaku></Kiyaku>
      </section>
    </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { UPDATE_ALL_COLLECTION } from '../store/mutation-types';
import Kiyaku from './Kiyaku.vue';

export default {
  name: 'Menu',
  // ローカルコンポーネントを登録
  components: {
    Kiyaku
  },
  props: {
    //outerSlide: String, //前後ページからのスライド方向
  },
  data() {
    return {
      //allCollections: [], // 全コレクション情報リスト配列
      kiyaku: "" //規約文章
    }
  },
  created: function() {
    //
  },
  computed: {
    // store内のゲッターを同名の算出プロパティに紐づけ
    ...mapGetters(['allCollections'])
  },
  methods: {
    // store情報のアクションを同名のメソッドに紐づけ
    ...mapActions([UPDATE_ALL_COLLECTION]),

    // [<]（戻る）ボタンのクリックイベント
    onBtnBack() {
      this.$nextTick(() => {
      // ホームへ
        this.$router.push({name: 'home', params: {outerSlide: 'slideL'}});
      });
    },
    // バックアップファイル（コレクション情報）の出力
    fileDownload() {
      if (confirm('バックアップファイルを作成します。よろしいですか？')) {
        //store内のコレクション情報リストをcollections配列へコピー
        //オブジェクト配列の値（ディープ）コピーで、map関数で配列を、Object.assign関数で中のオブジェクトを複製（※参照コピーだとstore内のデータを更新してしまうのでエラーとなるため）
        // バックアップファイルを作成
        let today = new Date();
        const fileName = "pico_" + today.getFullYear() + (today.getMonth()+1) + today.getDate() + today.getHours() + today.getMinutes() + ".json";
        // コレクション情報配列をデータ（JSON形式）へ変換し、出力
        const data = JSON.stringify(this.allCollections);
        const link = document.createElement("a");
        link.href = "data:text/plain," + encodeURIComponent(data);
        link.download = fileName;
        link.click();
      }
    },
    // バックアップファイル（コレクション情報）の復元
    fileImport() {
      if (confirm('バックアップファイルからデータを復元します。処理を実行すると現在のデータは上書きされます。よろしいですか？')) {
        // ファイル(.json)の読み込み
        let file = this.$refs.upfile.files[0];     
        // ファイルの拡張子チェック
        // ファイル名の最後尾から.の位置を取得
        var pos = file.name.lastIndexOf('.');
        // 拡張子がある（.の位置が>=0）場合
        if (pos !== -1) {
          // 拡張子を取得し、小文字に変換
          var ext = (file.name.slice(pos + 1)).toLowerCase();
          // 拡張子がjsonの場合
          if ((ext !== "json") && (ext !== "txt")) {
            // エラーメッセージを表示し、処理中断
            alert("適切なファイル拡張子ではありません。");
            return;
          }
        }
        // ファイルの中身をテキストデータへ変換
        let reader = new FileReader();
        reader.readAsText(file);
        // 成功時の処理
        reader.onload = () => {
          // データ（JSON形式）を読み込み、コレクション情報リスト配列へセット
          let data = null;
          try {
            data = JSON.parse(reader.result);
            console.log("json parsed");
            this[UPDATE_ALL_COLLECTION](data);
            alert("データ復元完了");
          // JSONパースが失敗した場合
          } catch(error) {
            console.log(error);
            alert("データ変換エラー\nファイルの復元に失敗しました");
          } finally {
            console.log('Import finally');
            data = null;
          }
        };
        // ファイル読込に失敗した時の処理
        reader.onerror = function() {
          console.log(reader.error);
          alert("ファイル読込エラー");
        };
      }
    }
  }
}
</script>

<style>
.menu header {
	background-color: #ffd700;
}
.menu h1 {
  position: relative;
  width: 100%;
  padding: 0 0 0 37px;
  margin: 0 10px 0 0;
  font-size: 1.8rem;
  line-height: 1.8;
}
.menu .container {
	padding: 60px 10px 20px;
}
.menuList {
  position: relative;
  border-bottom: 1px solid #ddd;
  padding: 10px 10px;
  margin: 0 0 10px;
}
.menuListBtn {
  padding: 10px 60px 10px 10px;
}
.menuList h2 {
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0 0 5px;
}
.menu .version span {
  position: absolute;
  right: 15px;
  font-weight: 400;
}
.menuList ul .dmyLi {
  display: none;
}
.menuList .menuButton {
  display: block;
  position: absolute;
  top: 35px;
  right: 10px;
  width: 40px;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 20px;
  cursor: pointer;
}
.btnDownload {
  background: url("../assets/images/ic_output.png") no-repeat center center;
  background-size: 16px 16px;
}
.btnDownload:active {
  background: #c8c2be url("../assets/images/ic_output_s.png") no-repeat center center;
  background-size: 16px 16px;
}
.btnUpload {
  background: url("../assets/images/ic_input.png") no-repeat center center;
  background-size: 16px 16px;
}
.btnUpload:active {
  background: #c8c2be url("../assets/images/ic_input_s.png") no-repeat center center;
  background-size: 16px 16px;
}
#upFile {
  display: none;
}

/* 右スライド（右から入り、右へ出る） */
.slideR-enter-active, .slideR-leave-active, .slideR-move {
  z-index: 3;
}
.slideR-enter, .slideR-leave-to {
  position: absolute;
  top: 0;
  transform: translateX(100vw);
}
.slideR-enter-to, .slideR-leave {
  transform: translateX(0px);
}
</style>