<template>
  <div class="home">
    <!-- ローディングウィンドウのコンポーネント -->
    <MyLoading :isLoadingActive="this.isLoading"></MyLoading>
    <header>
      <div class="header-inner">
        <!--<h1><span>ピコピコ</span>コレクション</h1>-->
        <h1><img src="../assets/images/logo.png" alt="ピココレ"></h1>
        <span class="btnMenu" @click="onBtnClick"></span>
      </div>
    </header>
    <transition name="slideL" appear>
    <div class="container">
      <ul class="gameConsole">
        <li :class="this.gameConsoles[0]['ini']"><a @click="onClick(0)"><span>ファミリー<br>コンピュータ</span></a></li>
        <li :class="this.gameConsoles[1]['ini']"><a @click="onClick(1)"><span>FCディスク<br>システム</span></a></li>
        <li :class="this.gameConsoles[2]['ini']"><a @click="onClick(2)"><span>ゲームボーイ</span></a></li>
        <li :class="this.gameConsoles[3]['ini']"><a @click="onClick(3)"><span>スーパー<br>ファミコン</span></a></li>
        <li :class="this.gameConsoles[4]['ini']"><a @click="onClick(4)"><span>PCエンジン</span></a></li>
        <li :class="this.gameConsoles[5]['ini']"><a @click="onClick(5)"><span>メガドライブ</span></a></li>
        <li :class="this.gameConsoles[6]['ini']"><a @click="onClick(6)"><span>ネオジオ</span></a></li>        
      </ul>
      <div class="dmyLi"></div>
    </div>
    </transition>
  </div>
</template>

<script>
import MyLoading from './Loading.vue';
import { mapActions } from 'vuex';
import { GAME_CONSOLES } from '../assets/data/game_consoles';
import { UPDATE_GAMECONSOLE, UPDATE_SOFT, UPDATE_DISPTARGET } from '../store/mutation-types';

export default {
  name: 'Home',
  components: {
    MyLoading
  },
  data() {
    return {
      gameConsoles: [], // ゲーム機情報配列リスト
      collections: [], // コレクション情報リスト
      isLoading: false // ローディングの表示
    }
  },
  created() {
    // ゲーム機情報の配列を取得
    this.gameConsoles = GAME_CONSOLES;
    // 選択中のゲーム機情報をクリア（初期化）
    this[UPDATE_GAMECONSOLE]("");
  },
  methods: {
    // store情報のアクションを同名のメソッドに紐づけ
    ...mapActions([UPDATE_GAMECONSOLE, UPDATE_SOFT, UPDATE_DISPTARGET]),
    // ゲーム機アイコンのクリックイベント
    onClick(gameConsole) {
      // ローディングを表示
      this.isLoading = true;
      // 1秒後に次の処理を実行（ローディングを表示させるため）
      const loading = new Promise(function(resolve) {
        setTimeout(function() {
          resolve();
        }, 500);
      });
      //選択したゲーム機情報をステートに保存
      loading.then(() => {
        this[UPDATE_GAMECONSOLE](gameConsole);
        //選択したゲーム機の全ソフトデータを取得
        import("../assets/data/softs_"+ this.gameConsoles[gameConsole]["ini"])
        .then((res) => {
          //ソフトリスト情報をステートに保存
          this[UPDATE_SOFT](res.softs);
          // 選択中のフィルターボタンをステートに保存
          this[UPDATE_DISPTARGET]("all");
          this.$nextTick(() => {
          // ソフト一覧ページへ
            this.$router.push({name: 'list', params: {outerSlide: 'slideR', dispDefault: true}});
          });
        });
      });
    },
    // メニューボタンのクリックイベント
    onBtnClick() {
      // ソフト一覧ページへ
      this.$router.push({name: 'menu'});
    },
  }
}
</script>

<style>
.home {
  z-index: 1;
}
.home header {
	background-color: #ffd700;
}
/* タイトル */
.home h1 {
  margin: 0 auto;
}
.home h1 img {
  width: 144px;
  height: 32px;
}
.btnMenu {
  display: block;
  position :absolute;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;
  background: url('../assets/images/ic_gear_s.png') no-repeat center center; 
  background-size: 16px 16px;
}
/* スライド時の行widthが短い場合に表示がずれる対策 */
.home .dmyLi {
  opacity: 0;
  height: 0;
  padding: 0;
}
.home .dmyLi:before {
  content: '０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９';
  z-index: 0;
}

.gameConsole {
  display: flex;
	flex-flow: row wrap;
  justify-content: space-between;
  align-items: top;
  z-index: 99;
}
.gameConsole li {
  width: 33%;
  min-height: 80px;
  padding: 0;
  margin: 0 0 20px;
}
.gameConsole li a {
  display: block;
  width: 100px;
  min-height: 80px;
  padding: 40px 0 0;
  margin: 0 auto;
  background: var(--consoleImage);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 32px 32px;
  text-align: center;
  color: #545454;
  cursor: pointer;
}
.gameConsole li a:hover {
  text-decoration: none;
  color: #545454;
}
.gameConsole li span {
  display: block;
  font-size: 1.4rem;
  font-weight: 700;
}

/* 左スライド（左から入り、左へ出る） */
.slideL-enter-active, .slideL-leave-active, .slideL-move {
  transition: transform 0.25s;
}
.slideL-enter, .slideL-leave-to {
  transform: translateX(-100vw);
}
.slideL-enter-to, .slideL-leave {
  transform: translateX(0px);
}
</style>